import React from 'react'
import { Helmet } from 'react-helmet'
import Home2Banner from './Home/Home2Banner'
import Home2Swiper from './Home/Home2Swiper'
import Home2Section3 from './Home/Home2Section3'
import Home2Section6 from './Home/Home2Section6'
import Home2Section8 from './Home/Home2Section8'
import Home2BrandSwiper from './Home/Home2BrandSwiper'
import Home2Testimonial from './Home/Home2Testimonial'
import Home2AskQuestion from './Home/Home2AskQuestion'
import Home2Section9 from './Home/Home2Section9'
import Home2Section10 from './Home/Home2Section10'
import Home2Section7 from './Home/Home2Section7'
import Home2Section5 from './Home/Home2Section5'
import Home2Section4 from './Home/Home2Section4'
import ContactForm1 from './common/ContactForm1'
import whatsApp from "../images/whatsapp.png";
import call from "../images/telephone.png"

function Home2({notify}) {

  const phoneNumber = "9045301702";

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const callHandler=()=>{
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl, "_blank");
  }
  return (
   <>
    <Helmet>
        <title>KushelDigi: Custom Web & Software Development Agency | Software Development Services
        </title>
        <meta
          name="description"
          content="KushelDigi is a leading mobile app development & ecommerce web development company shaping digital innovation since 2016. Elevate your business with our expert services."
        />
        <link rel="canonical" href="https://www.kusheldigi.com/" />
      </Helmet>

      <div className="home-main">


        <Home2Banner />

        <Home2Swiper />

        <Home2Section3 />

        <Home2Section4 />

         <Home2Section5 />

        <Home2Section6 />

        <Home2Section7 />

        <Home2Section8 />

       <Home2Section9 />

       <Home2Section10 />

        <Home2BrandSwiper />

        {/* <Home2Section12 /> */}

     <Home2Testimonial />

     <Home2AskQuestion />

     <ContactForm1 notify={notify} />

  {/*  buttons  */}
  <div className="whtsApBtns">
          <button onClick={whatAppHandler}>
            <img className="what-image-universal" src={whatsApp} alt="whatsApp-kusheldigi" title="whatsApp-kusheldigi" />
          </button>
          <button onClick={callHandler}>
            <img src={call} alt="call-icon" title="call-icon" />
          </button>

        </div>
        
      </div>
   </>
  )
}

export default Home2