import React from "react";
import "./section3.css";
import eye2 from "../../images/h2eye.webp";
import eyecircle from "../../images/h2eyecircle.webp";

function Home2Section3() {
  return (
    <div className="h2sec3wrap">
      
      <div className="sec3cont">

        {/* left side  */}
        <div className="h2sec3left">
          <div className="whoweare">
            <span> Who are we?</span>
          </div>

          <h3 className="h3eheaing">A Team of Expert Minds for Your Digital Success</h3>

          <p className="h3para">
           
Kushel Digi Solutions is one of the top solution providers for end-to-end digital needs as we specialize in web development, mobile app development, e-commerce platforms, and UI/UX design. With a strong foundation built over the last 8 years, we continue to serve various industries with a mission of customizing the digital experience in order to provide impetus for growth and innovation. We have a team of experienced professionals who brings your imagination into reality with state-of-the-art technologies and strategic insights. Choose us to bring your digital presence to a whole new level, and let us turn your business vision into a reality.

          </p>
        </div>


        <div className="h2sec3right">
      <img src={eyecircle} alt="Eye Circle" className="eye-circle" />
      <img src={eye2} alt="about kushel digi solutions" className="eye-img" />
      
       </div>

      </div>
    </div>
  );
}

export default Home2Section3;
