import React from 'react'
import ContactForm1 from './common/ContactForm1'
import whatsApp from "../images/whatap.png"
import scrollUp from "../images/scrollUp.png"

const Refund = () => {

  const phoneNumber = "+91-9045-301-702"
 
  const whatAppHandler = ()=>{
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  }


  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  return (
    <>
    <helmet>
    <link rel="canonical" href="https://www.kusheldigi.com/refund"/>
    </helmet>
    <div className="ser-main">
      <div className="refund-back">
          <div className="refund-sect">
              <h2>REFUND POLICY</h2>
          </div>
      </div>

      <div className="refund-section">
          <h3>refund Policy</h3>
          <p className='mt-2'>
          Following our core values of providing our customers with best quality and experience, we have laid down considerate cancellation and refund policy.
          </p>
          <p>
          Our projects are divided into goals and milestones.  Our project in certain stages starting with the analysis and curation of the work document’s scope. This document is used to verify and make sure both parties have a complete understanding of the work required therefore eliminating the potential of project cancellations, disputes, and other issues.

          </p>
          <p>Refunds will not be possible if time is spent on achieving a milestone and all the modules are completed. If there is a situation wherein the project is terminated mutually then the client will have control over all the completed work and all the further payments will be nullified. However, the previous payments or deposits will not be refunded in this case.
</p>
<p>No partial refunds will be provided for projects that are left mid-way through a milestone phase.
</p>
          <p>No cancellations will be entertained for the services our marketing team offers on special occasions as they are limited-time offers and cannot be nullified.
</p>
<p>Digital marketing and SEO packages are not refundable, yet the client can ask for a cancellation with a written notice in could be clarified as “in advance of one month
.</p>
<p>No refunds will be provided on deposits or payments for projects that have been abandoned or laid dormant for more than a minimum of 30 days.
</p>
      </div>
        <ContactForm1/>

            
       {/*  buttons  */}
<div className="whtsApBtns">

<button onClick={whatAppHandler}><img src={whatsApp} alt="" /></button>
<button onClick={scrollToTop}><img src={scrollUp} alt="" /></button>

</div>

      </div>
    </>
  )
}

export default Refund