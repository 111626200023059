import React from "react";
import "./section3.css";
import datah2 from "../../images/datah21.webp"
import light22 from "../../images/light22.webp"
import light3 from "../../images/light3.webp"
import gun2 from "../../images/gun2.webp"
import lap2 from "../../images/lap2.webp"
import slider from "../../images/slider365.webp"
import { MdArrowOutward } from "react-icons/md";
import { NavLink } from "react-router-dom";

const data = [

    {
         img:gun2 , 
        title:"Kickeez " , 
        para:"KICK-EEZ® sells a wide selection of rifle recoil pads and shooting supplies made of Sorbothane®, which provides shock absorption and reduces recoil effectively and quickly." , 
        type:"Firearms " , 
        type2:"B2B" , 
        link:"/kick-eez" , 
        alt:"kick eez"
    }, 
    {
      img:slider , 
     title:"Slides 365" , 
     alt:"slides 361" ,
     para:"At Slides365, we strive to help people tell their story effectively. Your invisible friend inside the PowerPoint helps you create the Professional Presentations." , 
     type:"B2B" , 
     type2:"E-commerce" ,
     link:"/Slidesdesign" , 
 }, 
    {
         img:light22 , 
        title:"DigitalMnA" , 
        para:"DigitalMnA is a full-service Merger and Acquisition company helping companies Sell and Buy assets by creating value." , 
        type:"Real Estate" ,
        type2:"" , 
        link:"/Digitalmna", 
        alt:"digital mna"
    
    }, 
    {
      img:datah2 , 
     title:"Top Brass Tactical " , 
     para:"Top Brass unwavering supporters of the frontline hero. we are passionate advocates of the 2nd amendment. we believe in hard work, justice and the American Way Of life." , 
     type:"ecommerce" , 
     type2:"tactical gear" , 
     link:"https://topbrasstactical.com/" , 
     alt:"top brass tactical"
 }, 
    {
         img:light3 , 
        title:"Auxible india " , 
        para:"Auxible India focuses on sound & visual solutions in the big and fancy professional world. It can be clubs, restaurants, bars, cafes, lounges, gyms, banquets, hotels, gaming zones, etc." , 
        type:"Entertainment" , 
        type2:""  , 
        link:"https://auxibleindia.com/" ,
        alt:"auxible india"
    }, 
    {
         img:lap2 , 
        title:"A&I Parts Center" , 
        para:"A & I Parts Center has proudly provided the best quality automotive parts and accessories to drivers in Stratford, Amarillo, Texline, Dalhart, Guymon, and beyond. Whether you need air filters, oil filters, A/C parts, or any other automotive parts." , 
        type:"Automobiles " , 
        type2:"E-commerce" , 
        link:"https://aipartscenter.com/" , 
        alt:"a&i parts center"
    }, 
]

function Home2Section6() {
  return (
    <div className="h2sec6wrap">

      <div className="h2sec6cont">
        <h3>OUR WORK </h3>

        <p className="s6para">See what makes Kushel Digi a top e-commerce development company </p>


        <div className="allSec6data">
             {
                data?.map((d , index)=>(
                  <NavLink to={d.link}>  <div key={index} className="singeldata">
                        <img  src={d.img} alt={d?.alt} title={d?.alt} />
                         
                         <div className="sdmid">
                            <span>{d.title}</span>
                            {/* <img src={dataarrow} alt="" /> */}
                            <div className="MdArrowOutwardbox">
                            <MdArrowOutward className="MdArrowOutward" />
                            </div>
                         </div>

                         <p className="dpara">{d.para}</p>

                         <div className="dttpes">
                            <span className="spandt1">{d?.type}</span>
                            <span className={`${d.type2 !== "" && "spandt1"}`}>{d.type2}</span>
                         </div>

                    </div>
                    </NavLink>
                ))
             }
        </div>

         <button className="s6viallwork">
            <span> VIEW ALL WORK</span>
         </button>


      </div>

    </div>
  );
}

export default Home2Section6;
