import React from 'react'
import BigHeader from './BigHeader'

function BigCommerce3() {
  return (
    <div className="home-main">

        <BigHeader />

    </div>
  )
}

export default BigCommerce3