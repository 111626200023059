import React, { useState } from 'react';
import g10 from "../../images/Group1001.webp";
import g11 from "../../images/ecodeve.webp";
import g12 from "../../images/webdesign.webp";
import g13 from "../../images/mobildappdev.webp";
import g14 from "../../images/cretivedesign.webp";
import g15 from "../../images/softdev.webp";
import g16 from "../../images/staffaugg.webp";
import g17 from "../../images/multileve.webp";
import showImg1 from "../../images/showimg1.webp";
import "./home2.css";
import { MdArrowOutward } from "react-icons/md";
import multimark from "../../images/multimarketing.webp"
import mobileapp from "../../images/mobileapp.webp"
import webdesignand from "../../images/webdesignand.webp"
import ecodev from "../../images/ecodev.webp"
import createdesign from "../../images/createdesign.webp"
import softwaraedesign from "../../images/softwaraedesign.webp"
import staffaug from "../../images/staffaug.webp"
import { NavLink, useNavigate } from 'react-router-dom';

const data = [
    {
        img: g10,
        title: "UI/UX",
        para: "We believe in creating user centric design that can give excellent user experiences to drive customer satisfaction. ",
        showImg: showImg1 , 
        link:"/ui-ux-design"
    },
    {
        img: g11,
        title: "E-commerce Development",
        para: "Kushel Digi builds robust, scalable e-commerce platforms according to your business needs.",
        showImg: ecodev , 
        link:"/ecommerce-development"
    },
    {
        img: g12,
        title: "Website Design & Development",
        para: "Kushel Digi known for designing highly functional websites that will increase your online presence.",
        showImg: webdesignand , 
        link:"/Graphic-Design"
    },
    {
        img: g13,
        title: "Mobile App Development",
        para: "Our expert professionals develop android and IOS app to bridge your business with customers on the move.",
        showImg: mobileapp , 
        link:"/mobile-application-development"
    },
    {
        img: g14,
        title: "Creative and Design",
        para: "We are committed to grow your brand's visibility and engagement with focused digital marketing strategies.",
        showImg: createdesign , 
        link:"/Slidesdesign"
    },
    {
        img: g15,
        title: "Software Development",
        para: "We believe in Developing exceptional software solutions to maximize efficiency and smoothen operations.",
        showImg: softwaraedesign , 
        link:"/custom-software-development"
    },
    {
        img: g16,
        title: "Staff Augmentation",
        para: "Kushel Digi helps to provide skilled professionals who will provide additional strength to your team for faster project execution.",
        showImg: staffaug , 
        link:"/staff-augmentation"
    },
    {
        img: g17,
        title: "Multi-Level Marketing",
        para: "We design effective MLM solutions to support your network growth and maximize profits.",
        showImg: multimark , 
        link:"/multi-level-Marketing"
    },
];

function Home2Section4() {
    const [hoveredItem, setHoveredItem] = useState(null);

    const navigate = useNavigate();

    return (
        <div className='h2s4wrap'>

            <div className="h2s4cont">
                 <div className='dashspandiv'>
                <span ></span><h3>What We Do</h3>
                 </div>
                <div className="wedoitems">
                    {
                        data?.map((d, i) => (
                           <NavLink to={d.link}> <div
                                key={i}
                                className="singleWeitem"
                                onMouseEnter={() => setHoveredItem(d)}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <div className="weitemleft">
                                    <img src={d.img} alt="" />
                                    <div className="itemconte">
                                        <p className='ictitle'>{d.title}</p>
                                        {/* Show para only on hover with animation */}
                                        {hoveredItem === d && (
                                            <p className='ipara'>{d.para}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="weiteemright">
                                    <span>Read More</span>
                                    <MdArrowOutward className='arrowlefts4' />
                                </div>
                            </div>
                            </NavLink>
                        ))
                    }
                </div>
            </div>
            {hoveredItem && (
                <div className="hoverImageContainer addMediaquery">
                    <img src={hoveredItem.showImg} alt="Hover Display" className="hoverImage" />
                </div>
            )}
            
        </div>
    );
}

export default Home2Section4;
