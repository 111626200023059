import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css"; 
import React, { useRef } from "react";
import "./styles.css";
import swiperimg2 from "../../images/r2.webp";
import swiperimg1 from "../../images/r3.webp";
import swiperimg7 from "../../images/r4.webp";
import swiperimg4 from "../../images/r5.webp";
import swiperimg5 from "../../images/r6.webp";
import swiperimg6 from "../../images/r7.webp";

function Home2Swiper() {
  const splideRef = useRef(null);

  const goPrev = () => {
    splideRef.current.splide.go('-1');
  };

  const goNext = () => {
    splideRef.current.splide.go('+1');
  };

  return (
    <div className="h2swiperwrap">
      
       <div className="h2swipcont">

     
      <Splide
        ref={splideRef}
        options={
          {
          type: 'loop',
          perPage: 4,
          start:0 , 
           // interval: 20,
           speed:2000 , 
          gap: '0px',
          autoplay: true,
          pagination: false,
          arrows: false,
          breakpoints: {
            440: {
              perPage: 7,
              gap: '10px',
            },
            750: {
              perPage: 5,
              gap: '10px',
            },  
            1400: {
              perPage: 4,
              gap: '0px',
            },
          },

        }}
        className="mySwiper32"
      >
        <SplideSlide>
          <div className="singswiperdiv">
            <img src={swiperimg1} alt="slides 361" title="slides 360" />
            <div className="swiplidebtn">
              <div className="insidebtns">
                <span>B2B</span>
                <span>E-commerce</span>
              </div>
              <p>Slides 365</p>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="singswiperdiv">
            <img src={swiperimg2} alt="sights and scopes" title="sights and scopes" />
            <div className="swiplidebtn">
              <div className="insidebtns">
                <span>B2B</span>
                <span>Drop Shipping</span>
              </div>
              <p>Sights and Scopes</p>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="singswiperdiv">
            <img src={swiperimg4} alt="servapure" title="servapure" />
            <div className="swiplidebtn">
              <div className="insidebtns">
                <span>E-commerce</span>
              </div>
              <p>Servapure</p>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="singswiperdiv">
            <img src={swiperimg5} alt="2nd amendment armory" title="2nd amendment armory" />
            <div className="swiplidebtn">
              <div className="insidebtns">
                <span>Ecommerce</span>
                <span>firearms</span>
              </div>
              <p>2ndamendmentarmory</p>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="singswiperdiv">
            <img src={swiperimg6} alt="top brass tactical" title="top brass tactical" />
            <div className="swiplidebtn">
              <div className="insidebtns">
                <span>Ecommerce</span>
                <span>tactical gear</span>
              </div>
              <p>Top Brass Tactical</p>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="singswiperdiv">
            <img src={swiperimg7} alt="2nd amendment guns" title="2nd amendment guns" />
            <div className="swiplidebtn">
              <div className="insidebtns">
                <span>Ecommerce</span>
                <span>firearms</span>
              </div>
              <p>2ndamendmentguns</p>
            </div>
          </div>
        </SplideSlide>
      </Splide>

      </div>

      <div className="swipebtns32">
        <button className="roudned" onClick={goPrev}>
          <svg
            className='home2rightarrow'
            width="12"
            height="12"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.1661 9.1131L1.1131 8.83393"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17L1.1131 8.83393L9.27917 0.947037"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <button className="roudned rotatesvg" onClick={goNext}>
          <svg
            className='home2rightarrow'
            width="12"
            height="12"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.1661 9.1131L1.1131 8.83393"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17L1.1131 8.83393L9.27917 0.947037"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

    </div>
  );
}

export default Home2Swiper;
