import React, { useState } from "react";
import bookpage1 from "../images/bookpage111.png";
import bookpage2 from "../images/bookpage2.jpg";
import whatsApp from "../images/whatap.png"
import scrollUp from "../images/scrollUp.png"

const Bookform = ({notify}) => {

  const phoneNumber = "+91-9045-301-702"
 
  const whatAppHandler = ()=>{
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  }


  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    city: "",
    date: "",
    time: "",
    additional_msg: ""
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch("https://backend.kusheldigi.com/contact4", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    });
    const data = await res.json();
    // console.log(data);
    // alert(data.message);
    notify( "success", data.message);
    setUser({
      first_name: "",
      last_name: "",
      email_address: "",
      city: "",
      date: "",
      time: "",
      additional_msg: ""
    });
  };


  return (
    <>
   
      <div className="ser-main">
        <div className="book_page">
          <div className="book_page-back">
            <div className="book_page-back1">
              <div className="book_page_head">
                <h1>Book an Appointment</h1>
                <hr />
              </div>
              <div className="book_page_para">
                <p>
                  Hi there! I'd be happy to help you book an appointment. What
                  kind of appointment are you looking to schedule? Let me know
                  and I'll do my best to assist you.
                </p>
              </div>
            </div>
            <div className="book_page-back2">
              <img src={bookpage1} alt="word" />
            </div>
          </div>
        </div>

        <div className="main_book_page">
          <div className="book_page_subcontainer">
            <div className="left_book_page">
              <img src={bookpage2} alt="" />
            </div>

             <form onSubmit={handleSubmit}>
            <div className="right_book_page">
              <h1>Book an Appointment</h1>
              <p>Please fill out the form below to make an appointment</p>
              <div className="input_book_page">
                <div>
                  <label htmlFor="first_name">First Name*</label>
                  <div>
                    <input onChange={handleChange} type="text" name="first_name" id="first_name" value={user.first_name} />
                  </div>
                </div>
                <div>
                  <label htmlFor="last_name">Last Name</label>
                  <div>
                    <input onChange={handleChange} type="text" name="last_name" value={user.last_name} id="last_name" />
                  </div>
                </div>
              </div>
              <div className="input_book_page">
                <div>
                  <label htmlFor="email_address">Email Address*</label>
                  <div>
                    <input
                      type="email"
                      name="email_address"
                      value={user.email_address}
                      onChange={handleChange}
                      id="email_address"
                      
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="city">Enter a City</label>
                  <div>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={user.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="input_book_page">
                <div>
                  <label htmlFor="date">Schedule Date</label>
                  <div>
                    <input
                      type="date"
                      name="date"
                      value={user.date}
                      onChange={handleChange}
                      id="date"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="time">Schedule Time</label>
                  <div>
                    <input
                      type="time"
                      name="time"
                      id="time"
                      onChange={handleChange}
                      value={user.time}
                    />
                  </div>
                </div>
              </div>
              <div className="textarea_book_page">
                <label htmlFor="additional_msg">Additional messege </label>
                <textarea name="additional_msg" id="additional_msg" value={user.additional_msg} onChange={handleChange} cols="10" rows="10"></textarea>
              </div>
              <button>
                <span>Book appointment</span>
              </button>
            </div>
            </form>

          </div>
        </div>

        <div className="whtsApBtns">

<button onClick={whatAppHandler}><img src={whatsApp} alt="" /></button>
<button onClick={scrollToTop}><img src={scrollUp} alt="" /></button>

</div>

      </div>
    </>
  );
};

export default Bookform;
