import React, { useState } from "react";
import ContactForm1 from "../../src/components/common/ContactForm1";
import { NavLink, useNavigate } from "react-router-dom";
import whatsApp from "../images/whatsapp.png";
import call from "../images/telephone.png"
import "./portfolio.css";

import po1 from "../images/po1.png";
import po2 from "../images/po2.png";
import po3 from "../images/po3.png";
import po4 from "../images/po4.png";
import po5 from "../images/po5.png";
import po6 from "../images/po6.png";
import po7 from "../images/po7.png";
import po8 from "../images/po8.png";
import po9 from "../images/po9.png";
import po10 from "../images/po10.png";
import po11 from "../images/po11.png";
import po12 from "../images/po12.png";
import po13 from "../images/po13.png";
import po14 from "../images/po14.png";
import po15 from "../images/po15.png";

import abimg1 from "../images/abImg1.png";
import abimg2 from "../images/abimg2.png";
import abimg3 from "../images/abimg3.png";
import abimg5 from "../images/abimg5.png";
import abimg6 from "../images/abimg6.png";
import abimg7 from "../images/abimg7.png";
import abimg8 from "../images/abimg8.png";
import abimg9 from "../images/abimg9.png";
import abimg10 from "../images/abimg10.png";
import abimg11 from "../images/abimg11.png";
import abimg12 from "../images/abimg12.png";
import abimg13 from "../images/abimg13.png";
import abimg14 from "../images/abimg14.png";
import abimg15 from "../images/abimg15.png";

import leftMost from "../images/leftmost.png"
import csRightMost from "../images/csRightMost.png"
import poFil from "../images/poFil.png";
import { Helmet } from "react-helmet";

const caseStudies = [
  {
    img: po1,
    img2: abimg1,
    btnText: "VIEW CASE STUDY" ,
    link:"/kick-eez",
    alt:"kusheldigi case study-KICK-EEZ",
    
  },
  {
    img: po2,
    img2: abimg2,
    btnText: "VIEW CASE STUDY",
    link:"/pro-track",
    alt:"kusheldigi Our Portfolio-PRO",

  },

  {
    img: po3,
    img2: abimg3,
    btnText: "VIEW CASE STUDY",
    link:"/rely",
    alt:"case study-rely",
  },

  {
    img: po4,
    btnText: "VIEW CASE STUDY",
    link:"/ceo",
    alt:"case study-CEO Wheels",
    
  },
  
  {
    img: po5,
    img2: abimg5,
    btnText: "VIEW CASE STUDY",
    link:"/solenoid-ninja",
    alt:"case study-Solenoid Ninja",
  },
  
  {
    img: po6,
    img2: abimg6,
    btnText: "VIEW LIVE  SITE",
    link:"https://www.grannysfinest.com/",
    target:"_blank",
    alt:"case study-Granny's finest",
  },

  {
    img: po7,
    img2: abimg7,
    btnText: "VIEW LIVE  SITE" , 
    link:"https://speckproducts.com/",
    target:"_blank",
    alt:"case study-Speck",
  },
  
  
  {
    img: po8,
    img2: abimg8,
    btnText: "VIEW LIVE  SITE",
    link:"https://www.bulletproof.com/",
    target:"_blank",
    alt:"case study-Bulletproof",
  },

  {
    img: po9,
    img2: abimg9,
    btnText: "VIEW LIVE  SITE",
    link:"https://skknbykim.com/",
    target:"_blank",
    alt:"case study-skkn",
    
  },
  
  {
    img: po10,
    img2: abimg10,
    btnText: "VIEW LIVE  SITE",
    link:"https://www.woolrich.com/us/en/",
    target:"_blank",
    alt:"case study-Woolrich",

  },

  {
    img: po11,
    
    img2: abimg11,
    btnText: "VIEW LIVE  SITE" , 
    link:"/Digitalmna",
    
    alt:"case study-DigitalMnA Partners",

  },

  {
    img: po12,
    img2: abimg12,
    btnText: "VIEW LIVE  SITE" , 
    link:"https://chingsingh.com/",
    target:"_blank",
    alt:"case study-Chingsingh",

  },

  {
    img: po13,
    img2: abimg13,
    btnText: "VIEW LIVE  SITE" , 
link:"https://hazoorilaljewellers.com/",
target:"_blank",
alt:"case study-Hazoorilal",

  },

  {
    img: po14,
    img2: abimg14,
    btnText: "VIEW LIVE  SITE" , 
    link:"https://www.karolbaghjewellers.ca/",
    target:"_blank",
    alt:"case study-Karol Bagh",

  },

  {
    img: po15,
    img2: abimg15,
    btnText: "VIEW LIVE  SITE" , 
    link:"/Slidesdesign",
    alt:"case study-Slides365",
  },
];

const Portfolio = () => {

  const navigate = useNavigate();


  const phoneNumber = "9045301702";

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const callHandler=()=>{
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl, "_blank");
  }


  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const topData = [
    {
      num: "12+",
      title: "Years on the Market",
    },
    {
      num: "90+",
      title: "Experts on Board",
    },
    {
      num: "300+",
      title: "Complete Projects",
    },
  ];

  return (
    <>
    <Helmet>
    <title>Case studies of KushelDigi Solutions</title>
     <meta
         name="description"
         content="Kushel digi has been serving its clients for over 6 years, earning the reputation of a trusted IT partner. Browse some of our success stories."
     /> 
    <link rel="canonical" href="https://www.kusheldigi.com/portfolio"/>
    </Helmet>
      <div className="ser-main">
      

        {/* home banner */}
        <div className="nwPortSec1">
          <div className="nwPorSec1Con">
            <div className="nwPorInter">
              <h1>Portfolio</h1>

              <p>
                Kushel digi has been serving its clients for over 6 years,
                earning the reputation of a trusted IT partner. Browse some of
                our success stories.
              </p>
            </div>

          <a href="/contact-us">

            <button className="nwPorDisBtn">
              <span>Discuss Your Project</span>
            </button>
          </a>
          </div>
        </div>

        {/* second section  */}
        <div className="nwPortSec2">

          <img src={leftMost} className="leftMost" alt=" portfolio-design" title=" portfolio-design" />

          <div className="nwPortSec2Cont">
            {/* top  */}

            <div className="nwPS2_top">
              {topData?.map((data, index) => (
                <div key={index} className="nwPS2_sin">
                  <h2>{data?.num}</h2>
                  <p className="nwS2line"></p>
                  <p className="nwS2Titl">{data?.title}</p>
                </div>
              ))}
            </div>

            {/* bottom */}

            <div className="mwPS2_bttom">

            <img src={csRightMost} className="csRightMost" alt=" portfolio-design" title=" portfolio-design" />

              <h2 className="nwPs2head">Case studies</h2>

              <div className="mwPS2_boxes">
                {caseStudies.map((data, index) => (
                  <div className="sinCaseStudy" key={index}>
                    <img src={data.img} alt={data.alt} title={data.alt}/>

                    <div className="csInsiImg">
                      <img src={data?.img2} alt={data.alt} title={data.alt} />
                      
                        
                     <a target={data?.target} href={data?.link}>

                      <button  className="viewCSbTN">
                 
                        <span>{data?.btnText}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.175 13H4V11H16.175L10.575 5.4L12 4L20 12L12 20L10.575 18.6L16.175 13Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      </a>
                      
                    </div>


                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <ContactForm1 />



        <div className="whtsApBtns">
          <button onClick={whatAppHandler}>
            <img className="what-image-universal" src={whatsApp} alt="whatsApp-kusheldigi" title="whatsApp-kusheldigi" />
          </button>
          <button onClick={callHandler}>
            <img src={call} alt="call-icon" title="call-icon" />
          </button>

        </div>
      </div>
    </>
  );
};

export default Portfolio;
