import React from 'react'
import "./askques.css"
import item1 from "../../images/s10item1.webp"
import hospital1 from "../../images/hospital1.webp"
import real1 from "../../images/real1.webp"
import healthcare from "../../images/healthcare.webp"
import education1 from "../../images/education1.webp"
import finance1 from "../../images/finance1.webp"
import tenonoloy1 from "../../images/tenonoloy1.webp"
import fashion1 from "../../images/fashion1.webp"
import meida1 from "../../images/meida1.webp"
import fitness1 from "../../images/fitness1.webp"
import automotive1 from "../../images/automotive1.webp"
import profession1 from "../../images/profession1.webp"


const data = [
    {
     img: item1 , 
     title:"E-commerce" , 
     desc:"Transform your online store with our customized eCommerce solutions."
    } , 
    {
     img: hospital1 , 
     title:"Hospitality" , 
     desc:"Enhance guest experiences with our state-of-the-art hospitality solutions."
    } , 
    {
     img: real1 , 
     title:"Real Estate" , 
     desc:"Showcase properties and close deals faster using our real estate immersive digital platforms."
    } , 
    {
     img: healthcare , 
     title:"Healthcare" , 
     desc:"Revolutionize the way of treating and managing patients with our healthcare solutions."
    } , 
    {
     img: education1 , 
     title:"Education" , 
     desc:"Enhance learning experiences with our customized e-learning solutions."
    } , 
    {
     img: finance1 , 
     title:"Finance" , 
     desc:"Drive financial growth with secure, scalable and user-friendly Fintech solutions."
    } , 
    {
     img: tenonoloy1 , 
     title:"Technology" , 
     desc:"Bring more efficiency into your business with our best technology development services."
    } , 
    {
     img: fashion1 , 
     title:"Fashion and Apparel" , 
     desc:"Give shine to your brand through visually stunning fashion eCommerce platforms."
    } , 
    {
     img: meida1 , 
     title:"Entertainment & Media" , 
     desc:"Engage with audiences by entertainment through dynamic, interactive platforms."
    } , 
    {
     img: fitness1 , 
     title:"Fitness and Wellness" , 
     desc:"Experience seamless digital experiences in the delivery of health and wellness services."
    } , 
    {
     img: automotive1 , 
     title:"Automotive" , 
     desc:"Drive forward the automotive business by using our specialized online solutions."
    } , 
    {
     img: profession1 , 
     title:"Professional Services" , 
     desc:"Enhance your service offering with our comprehensive digital solution."
    } , 
]

function Home2Section10() {
  return (
    <div className='h2s10wrap'>

        <div className="h2s10cont">

            <div className="s10top">
                <h3> Empowering industries with out expert solutions    </h3>
                <p>We have expertise in multiple industries, providing custom digital solutions for growth and success. Be it in transforming customer experience or operational excellence, we engineer technology that caters specifically to your business needs. </p>
            </div>

            <div className="s10items">
              {
                data?.map((d , i)=>(
                    <div key={i} className="singles1item">
                           <img src={d.img} alt="" />
                           <div className="itemdata">
                            <div className='dtitlhead'>
                            <span>{d.title}</span>
                            </div>
                            <p>{d.desc}</p>
                           </div>
                    </div>
                ))
              }
            </div>

        </div>

    </div>
  )
}

export default Home2Section10