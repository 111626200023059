import React from 'react'
import tge from '../images/tge.png'
import { NavLink } from 'react-router-dom'
const Success = () => {
    return (
        <div className='ser-main'>
            <div className='main_signj'>
                <div className="sign_imk">
                    <img src={tge} alt="tge" />
                    <h2>Thank You</h2>
                    <p>Your form has been submitted successfully.</p>
                   <NavLink to="/"><button className="about-btn"><span>Back to Home</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path></svg></button></NavLink>
                </div>
            </div>
        </div>
    )
}

export default Success
