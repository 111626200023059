import React from 'react'
import img14 from "../../images/earthpap.png";
import img15 from "../../images/Group 1000006826.png";
import img16 from "../../images/shot.png";
import img18 from "../../images/Group 1000006829.png";
import img19 from "../../images/Frame (1).png";

const Process = () => {
  return (
    <>
       <div className="fiveee-createe">
        <div className="five-create dine-1234">
                <p className="intutive">
                    Our Process 
                </p>
                <p className="leverage">
                    Unlock your true potential, leverage the best of technology, and
                    create apps based on modern algorithms with our React Native
                    development experts.
                </p>
                <div className="triple-mainbox">
                    <div className="discover-box">
                        {/* <div className="line"> */}
                            <img src={img14} id="delo"></img>
                        {/* </div> */}
                        <p className="discover">Discover</p>
                        <p className="project">
                            There goes a lot in the back to turn your project into a success
                            story.
                        </p>
                    </div>
                    <hr className="horzt" />
                    <div className="discover-box">
                        <img src={img15} id="delo"></img>
                        <p className="discover">Define</p>
                        <p className="project">
                            Determining the scope of how we can convert your idea into
                            reality.
                        </p>
                    </div>
                    <hr className="horzt" />
                    <div className="discover-box">
                        <img src={img16} id="delo"></img>
                        <p className="discover">Design</p>
                        <p className="project">Offering you breakthrough app prototypes</p>
                    </div>
                </div>
                <div className="double-mainbox">
                    <div className="discover-box">
                        <img src={img18} id="delo"></img>
                        <p className="discover">Analyze & Deliver</p>
                        <p className="project">
                            Stable and secure application served at your table as per the
                            deadlines
                        </p>
                    </div>
                    <hr className="horzt" />
                    <div className="discover-box">
                        <img src={img19} id="delo"></img>
                        <p className="discover">Develop</p>
                        <p className="project" id="important">
                            We’d commence development with a - milestone based plan of action.
                        </p>
                    </div>
                    <hr id="line2" />
                    <hr id="vertical" />
                </div>
            </div>
            </div>
    </>
  )
}

export default Process
