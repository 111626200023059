import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import StafArgu from "./components/StafArgu";
import USA from "./components/USA";
import MobileApp from "./components/MobileApp";
import WebDevelopmentUSA from "./components/WebDevelopmentUSA";
import Privacy from "./components/Privacy";
import Refund from "./components/Refund";
import Terms from "./components/Terms";
import OurPartner from "./components/OurPartner";
import Kickiz from "./components/Kickiz";
import DigitalMarketing from "./components/DigitalMarketing";
import Uiux from "./components/Uiux";
import HireTeam from "./components/HireTeam";
import Error from "./components/Error";
import Portfolio from "./components/Portfolio";
import "./index2.css";
import Career from "./components/Career";
import About from "./components/About";
import ScrollToTop from "./components/Utils/ScrollToTop";
import Android from "./components/Android";
import Ios from "./components/Ios";

import Native from "./components/Native";
// import BigCommerce from "./components/BigCommerce";
import Solenoid from "./components/Solenoid";
import ProTrucks from "./components/ProTrucks";
import Rely from "./components/Rely";
import JonesBuilt from "./components/JonesBuilt";
import Ceo from "./components/Ceo";
import Kotlin from "./components/Kotlin";
import Shopify from "./components/Shopify";
import Magento from "./components/Magento";
import WooCommerce from "./components/WooCommerce";
import Php from "./components/Php";
// import About from "./components/About";
// import Services1 from "./components/Services1";
import Solution from "./components/Solution";
import PhpHire from "./components/PhpHire";
import ReactNativeHire from "./components/ReactNativeHire";
import GraphicDesign from "./components/GraphicDesign";
import AndroidHire from "./components/AndroidHire";
import IosHire from "./components/IosHire";
import FluterHire from "./components/FluterHire";
import HireNodeJs from "./components/HireNodeJs";
import ReactJsHire from "./components/ReactJsHire";
import LaravelHire from "./components/LaravelHire";
import HireWordpress from "./components/HireWordpress";
import WordPress from "./components/WordPress";
import Html from "./components/Html";
import Bigcommerce1 from './components/bigcommerce1';
// import Nodejsdev from './components/Nodejsdev';
import Reactjsdev from './components/Reactjsdev';
import Nodejsdev2 from "./components/Nodejsdev2";
import Bookform from "./components/Bookform";
import Smo from "./components/Smo";
import Digitalmna from "./components/Digitalmna";
import Slidesdesign from "./components/Slidesdesign";
import Smm from "./components/Smm";
import Club from "./components/Club";
import Ppc from "./components/Ppc";
import Seo from "./components/Seo";
import Services from "./components/Service";
import Industries from "./components/Industries";
import { useState } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Mlm from "./components/Mlm";
import CustomSoftware from "./components/CustomSoftware";
// Ankit Add
import USAnew from "./components/USAnew.jsx";
import Singapoor from "./components/Singapor.jsx";
import Ireland from "./components/Ireland.jsx";
import Flutters from "./components/Flutters.jsx";
import Headless from "./components/Headless.jsx";
import Next from "./components/Nextpage.jsx";
import Home2 from "./components/Home2.jsx";
import Success from "./components/Success.jsx";
import BigCommerce3 from "./components/BigCommerce3/BigCommerce3.jsx";
function App() {
  const [pop,setPop] = useState(false);
  const notify = (status, message) => {
    if (status) {
      NotificationManager.success(message, 'Success! ');
    }
    else {
      NotificationManager.error(message, 'Failed! ');
    }
  };

  return (
    <>
   <NotificationContainer/>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home2 notify={notify} />} />
          <Route path="/bigcommerce3" element={<BigCommerce3 />}  /> 
          {/* <Route path="/" element={<Home notify={notify} />} /> */}
          <Route path="/success" element={<Success/>}/>

          <Route path="/custom-software-development" element={<CustomSoftware/>} />
          <Route path="/contact-us" element={<Contact notify={notify} />} />
          <Route path="/staff-augmentation" element={<StafArgu notify={notify} />} />
          {/* <Route path="/service1" element={<Services1/>}/> */}
          <Route path="/ecommerce-development" element={<USA notify={notify} pop={pop} setPop={setPop} />} />
          <Route
            path="/mobile-application-development"
            element={<MobileApp notify={notify} />}
          />
          <Route path="/web-development" element={<WebDevelopmentUSA notify={notify} />} />
          <Route
            path="/digital-marketing-agency"
            element={<DigitalMarketing notify={notify} />}
          />
          <Route path="/ui-ux-design" element={<Uiux notify={notify} />} />
          <Route path="/hire" element={<HireTeam />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/partner" element={<OurPartner notify={notify} />} />
          {/* ============internal route============ */}
          <Route path="/kick-eez" element={<Kickiz />} />
          <Route path="/solenoid-ninja" element={<Solenoid />} />
          <Route path="/pro-track" element={<ProTrucks />} />
          <Route path="/rely" element={<Rely />} />
          <Route path="/jones" element={<JonesBuilt />} />
          <Route path="/ceo" element={<Ceo />} />
          {/* ===========internal route end================ */}
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/career" element={<Career notify={notify} />} />
          <Route path="/about" element={<About notify={notify} />} />
          <Route path="/android" element={<Android notify={notify} />} />
          <Route path="/ios" element={<Ios />} />
          {/* <Route path="/Flutter" element={<flutter notify={notify} />} /> */}
          <Route path="/react-native" element={<Native />} />
          {/* <Route path="/bigComm" element={<BigCommerce />} /> */}
          <Route path="/kotlin" element={<Kotlin />} />
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/magento" element={<Magento />} />
          <Route path="/wooCommerce" element={<WooCommerce />} />
          <Route path="/php" element={<Php />} />

          <Route path="/solutions" element={<Solution />} />
          <Route path="/Graphic-Design" element={<GraphicDesign notify={notify} />} />
          <Route path="/flutter" element={<Flutters notify={notify}/>}/>
          <Route path="/wordpress" element={<WordPress />} />
          <Route path="*" element={<Error />} />

          {/* =============hire pages route=========== */}
          <Route path="/hire/php-developer" element={<PhpHire />} />
          <Route path="/hire/react-native-developer" element={<ReactNativeHire />} />

          <Route path="/hire/android-developer" element={<AndroidHire />} />
          <Route path="/hire/ios-developer" element={<IosHire />} />
          <Route path="/hire/flutter-developer" element={<FluterHire />} />
          <Route path="/hire/nodejs-developer" element={<HireNodeJs />} />
          <Route path="/hire/react-js-developer" element={<ReactJsHire />} />
          <Route path="/hire/laravel-developer" element={<LaravelHire />} />
          <Route path="/hire/wordpress-developer" element={<HireWordpress />} />

          <Route path="/html" element={<Html />} />
          <Route path="/bigcommerce-development" element={<Bigcommerce1 />} />
          <Route path="/bigcommerce" element={<Bigcommerce1 />} />
          <Route path="/react-js" element={<Reactjsdev />} />
          <Route path="/node-js" element={<Nodejsdev2 />} />
          <Route path="/multi-level-Marketing" element={<Mlm />} />
          <Route path="/Bookform" element={<Bookform notify={notify} />} />
          <Route path="/smo" element={<Smo notify={notify} />} />
          <Route path="/Digitalmna" element={<Digitalmna notify={notify} />} />
          <Route path="/Slidesdesign" element={<Slidesdesign notify={notify} />} />
          <Route path="/Smm" element={<Smm notify={notify} />} />
          <Route path="/Ppc" element={<Ppc notify={notify} />} />
          <Route path="/seo" element={<Seo notify={notify} />} />
          <Route path="/industries" element={<Industries notify={notify} />} />
          <Route path="/Club" element={<Club notify={notify} />} />
          <Route path="/Service" element={<Services />} />
         
          <Route path="/web-development-in-usa" element={<USAnew notify={notify} />} />
          <Route path="/singapoor" element={<Singapoor notify={notify} />} />
          <Route path="/ireland" element={<Ireland notify={notify} />} />
          <Route path="/headless-commerce" element={<Headless notify={notify} />} />
          <Route path="/next-js" element={<Next notify={notify} />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
